/* styles for ExpandableImage */

.img-popup {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left:50%;
    transform: translateX(-50%);
    width: 80vw; /* not full width */
    height: 80vh; /* not full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
    border-radius: 30px;
    margin-top:10vh;
    border-width: 0;
}

.ei-figure-container {
    display:flex;
    flex-direction: column;
    justify-content: center;
    text-align: justify;
    max-width: 80vw;
    margin: auto;
}

.ei-large {
    margin-top:20px;
    max-height: 50vh;
    width: 100%;
    object-fit: contain;
}


/* The Close Button */
.ei-dialog-close {
    position:absolute;
    top:10px;
    right:10px;
    color: #aaa;
    font-size: 2em;
    font-weight: bold;
  }
  
  .ei-dialog-close:hover,
  .ei-dialog-close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

.ei-small-img-container {
    position: relative;
    margin-right: 20px;
    margin-bottom: 20px;
}

.ei-small-img-container:hover,
.ei-small-img-container:focus {
  cursor: pointer;
}

.ei-small {
    max-width: 500px;
    max-height: 500px;
    border-radius: 8px;
}

.ei-text-overlay {
    position:absolute;
    top: 10px;
    left: 10px;
    color: var(--yellow);
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.8); /* Black w/ opacity */
    border-radius: 8px;
    padding: 5px;
}

.ei-panel-id {
    display: inline-block;
    padding-right: 10px;
    font-size: 2em;
}

.ei-panel-blurb {
    display: inline-block;
    font-size: smaller;
}

.ei-caption-text {
    padding: 20px;
    max-width: 80%;
    font-size: smaller;
    margin: auto;
}

/* mobile styles */
@media (max-width: 767px) {
    .img-popup {
        width: 100vw; /* Full width */
        height: 90vh; /* Full height */
        padding: 10px;
        border-radius: 10px;
    }
    .ei-small-img-container {
        margin-right: 0px;
        margin-bottom: 10px;
    }

    .ei-small {
        max-width: 90vw;
        max-height: 100vw;
        border-radius: 8px;
    }
    .ei-large {
        max-width: 100vw;
        max-height: 100vw;
        object-fit: contain;
    }

    .ei-caption-text {
        max-width: 100%;
    }
}
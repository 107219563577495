:root {
  --blue: #47B1F3;
  --darkblue: #2d54b0;
  --yellow: #FFFA81;
  --orange: #DE654C;
  --grey: #949494;
  --pink: #EB7FC8;
  --white: #FFF2F2;
  --purple: #C941F2;
  --green:#2da264;
  --red: #F24141;
  --gray: #E4E4E4;
  --text-grey: hsl(0, 0%, 36%);
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

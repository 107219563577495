.icon-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    margin: -3vw;
    margin-top: 0vw;
  }
  
  .icon-grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border: 0px;
    margin: -10px;
  }

  .share__menu {
    position: fixed;
    top: 10vh;
    background-color: hsla(0, 0%, 100%, 0.574);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    border-radius: 5vh;
    width: 80%;
    height: 40%;
    padding: 3rem 3rem ;
  }

  @media (min-width: 1200px) {
    .share__menu{
      width: 40%;
    }
    .icon-grid-item {
      padding: 1.5vh;
      border: 0px;
      margin: -10px;
    }

    .icon-grid {
      grid-template-columns: repeat(5, 1fr);
      margin: 3vw;
      margin-top: 0vw;
    }

    .share_icon {
      margin-right: 2vw;
      color: var(--white);
    }
  }

    /* Tablet styles */
    @media (min-width: 769px) and (max-width: 1199px) {
    .share__menu{
      width: 40%;
    }
    .icon-grid-item {
      padding: 1.5vh;
      border: 0px;
      margin: -10px;
    }

    .icon-grid {
      grid-template-columns: repeat(5, 1fr);
      margin-top: 0vw;
    }

    .share_icon {
      margin-right: 2vw;
      color: var(--white);
    }
  }
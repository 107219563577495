.menu__close {
    position: absolute;
    top: 1rem;
    left: 1.5rem;
  }

.menu_wrapper {
    position: fixed;
    top: 10vh;
    right: 100%;
    background-color: hsla(0, 0%, 100%, 0.556);
    backdrop-filter: blur(16px);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: blur(16px);
    border-radius: 5vh;
    width: 80%;
    padding: 2rem 2rem ;
    transition: right 0.4s;
}

.menu_text {
    font-family: "Futura", sans-serif;
    font-weight: bold;
    font-size: 3vh;
    margin-top: 1vh;
    text-align: center;
    display: block;
}

.menu_link{
    text-decoration: none;
    margin-bottom: 2vh;
    text-decoration: none;
    color: var(--blue);
}

.menu_link:hover{
    color: var(--yellow);
    cursor:pointer;
}
